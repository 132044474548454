import Logo from "../img/logo-white.png";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import TestItem from "../img/item1.png";
import {
  centralSauceData,
  centralItemData,
  bukittimahItemData,
  jjajangItemData,
  bbqItemData,
  bbqjjajangItemData,
} from "../data";

const Navbar = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div id="navbar">
      <div className="navbar container">
        <div className="title-container flex-column">
          <h3>마지막 업데이트: 2024년 9월 21일 8:32AsM</h3>
          <h1>O.BBa Central Kitchen 재고현황</h1>
        </div>
        <div className="logo-container">
          <img src={Logo} alt="logo" />
        </div>
      </div>
      <div className="nav-menu-container container">
        <Box sx={{ width: "100%", flexDirection: "flex-start" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab label="센트럴키친" value="1" />
                <Tab label="77번지" value="2" />
                <Tab label="59번지" value="3" />
                <Tab label="63번지" value="4" />
                <Tab label="19번지" value="5" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ height: "100%", padding: "10px 0 0 0" }}>
              <h1 className="item-title">소스</h1>
              <div className="item-card-container">
                {centralSauceData.map((item, index) => (
                  <div className="item-card" key={index}>
                    <div className="item-card-img">
                      <img src={item.img} alt={item.title} />
                    </div>
                    <div className="item-card-content flex-row">
                      <h3 className="item-card-title">{item.title}</h3>
                      <p className="item-card-qty">{item.qty} 개</p>
                    </div>
                  </div>
                ))}
              </div>
              <h1 className="item-title">매장물품</h1>
              <div className="item-card-container">
                {centralItemData.map((item, index) => (
                  <div className="item-card" key={index}>
                    <div className="item-card-img">
                      <img src={item.img} alt={item.title} />
                    </div>
                    <div className="item-card-content flex-row">
                      <h3 className="item-card-title">{item.title}</h3>
                      <p className="item-card-qty">{item.qty} 개</p>
                    </div>
                  </div>
                ))}
              </div>
            </TabPanel>
            <TabPanel value="2" sx={{ height: "100%", padding: "10px 0 0 0" }}>
              <h1 className="item-title">매장물품</h1>
              <div className="item-card-container">
                {jjajangItemData.length > 0 ? (
                  jjajangItemData.map((item, index) => (
                    <div className="item-card" key={index}>
                      <div className="item-card-img">
                        <img src={item.img} alt={item.title} />
                      </div>
                      <div className="item-card-content flex-row">
                        <h3 className="item-card-title">{item.title}</h3>
                        <p className="item-card-qty">{item.qty}개</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <h1 className="no-stock">재고없음</h1>
                )}
              </div>
            </TabPanel>
            <TabPanel value="3" sx={{ height: "100%", padding: "10px 0 0 0" }}>
              <h1 className="item-title">매장물품</h1>
              <div className="item-card-container">
                {bbqjjajangItemData.length > 0 ? (
                  bbqjjajangItemData.map((item, index) => (
                    <div className="item-card" key={index}>
                      <div className="item-card-img">
                        <img src={item.img} alt={item.title} />
                      </div>
                      <div className="item-card-content flex-row">
                        <h3 className="item-card-title">{item.title}</h3>
                        <p className="item-card-qty">{item.qty}개</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <h1 className="no-stock">재고없음</h1>
                )}
              </div>
            </TabPanel>
            <TabPanel value="4" sx={{ height: "100%", padding: "10px 0 0 0" }}>
              <h1 className="item-title">매장물품</h1>
              <div className="item-card-container">
                {bbqItemData.length > 0 ? (
                  bbqItemData.map((item, index) => (
                    <div className="item-card" key={index}>
                      <div className="item-card-img">
                        <img src={item.img} alt={item.title} />
                      </div>
                      <div className="item-card-content flex-row">
                        <h3 className="item-card-title">{item.title}</h3>
                        <p className="item-card-qty">{item.qty}개</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <h1 className="no-stock">재고없음</h1>
                )}
              </div>
            </TabPanel>
            <TabPanel value="5" sx={{ height: "100%", padding: "10px 0 0 0" }}>
              <h1 className="item-title">매장물품</h1>
              <div className="item-card-container">
                {bukittimahItemData.length > 0 ? (
                  bukittimahItemData.map((item, index) => (
                    <div className="item-card" key={index}>
                      <div className="item-card-img">
                        <img src={item.img} alt={item.title} />
                      </div>
                      <div className="item-card-content flex-row">
                        <h3 className="item-card-title">{item.title}</h3>
                        <p className="item-card-qty">{item.qty}개</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <h1 className="no-stock">재고없음</h1>
                )}
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
};

export default Navbar;
