import React, { useState, useEffect } from "react";
import Login from "./components/Login";
import Navbar from "./components/Navbar";

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (authenticated) {
      // Reset zoom level to 100%
      document.body.style.zoom = "100%";

      // Optionally, you can also force a re-render or refresh to ensure the zoom is applied immediately
      // (not usually necessary but can be used if zoom issues persist)
      setTimeout(() => window.dispatchEvent(new Event("resize")), 0);
    }
  }, [authenticated]);

  return (
    <>
      <div>
        {authenticated ? <Navbar /> : <Login onLogin={setAuthenticated} />}
      </div>
    </>
  );
}

export default App;
