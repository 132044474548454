import React, { useState } from "react";
import Spinner from "./Spinner";

const Login = ({ onLogin }) => {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner

    setTimeout(() => {
      if (
        (userId === "obba5858" && password === "588258!") ||
        (userId === "Obba5858" && password === "588258!")
      ) {
        // Ensure layout updates before changing login state
        requestAnimationFrame(() => {
          onLogin(true); // Successful login
        });
      } else {
        alert("로그인 정보를 다시 확인해주세요");
        setLoading(false); // Hide spinner if authentication fails
      }
    }, 2500); // Simulated delay
  };

  return (
    <div id="login-form-wrapper">
      <div className="login-form-wrapper container">
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Login</h2>
          <input
            type="text"
            placeholder="User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            disabled={loading} // Disable input during loading
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading} // Disable input during loading
          />
          <button type="submit" disabled={loading}>
            {loading ? <Spinner /> : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
