// 소스이미지
import BokeumImg from "./img/central/bokeum-sauce.png";
import BuchuImg from "./img/central/buchu-sauce.png";
import BudaeImg from "./img/central/budae-sauce.png";
import ChickenPDImg from "./img/central/chicken-powder.png";
import DongchimiImg from "./img/central/dongchimi-sauce.png";
import FlavouringImg from "./img/central/flavouring-sauce.png";
import GoldImg from "./img/central/gold-sauce.png";
import JjajangImg from "./img/central/jjajang-sauce.png";
import JjamppongImg from "./img/central/jjamppong-sauce.png";
import JjolmyunImg from "./img/central/jjolmyun-sauce.png";
import JokbalImg from "./img/central/jokbal-sauce.png";
import KalImg from "./img/central/kal-sauce.png";
import KkanpungImg from "./img/central/kkan-sauce.png";
import KongImg from "./img/central/konggaru.png";
import MariPorkGalbiImg from "./img/central/marinated-pork-sauce.png";
import JeyukYangnyum from "./img/central/jeyuk-sauce.png";
import MyulchiImg from "./img/central/myulchi-sauce.png";
import OnyuksuImg from "./img/central/on-yuksu-sauce.png";
import PancakeImg from "./img/central/pancake-powder.png";
import PorkCutletImg from "./img/central/pork-cutlet-sauce.png";
import RamenImg from "./img/central/ramen-sauce.png";
import RicePowderImg from "./img/central/rice-powder.png";
import ShabuImg from "./img/central/shabu-sauce.png";
import MariCrabImg from "./img/central/soy-crab-saucce.png";
import MariJeyukImg from "./img/central/soy-jeyuk-sauce.png";
import SpicyCrabImg from "./img/central/spicy-carb-sauce.png";
import SpicyJokbalImg from "./img/central/spicy-jokbal-sauce.png";
import SpicyPorkCutletImg from "./img/central/spicy-porkcutlet-sauce.png";
import TangImg from "./img/central/tang-sauce.png";
import TeriImg from "./img/central/teriyakki-sauce.png";
import TteokbokiImg from "./img/central/tteokboki-sauce.png";
import UdonImg from "./img/central/udon-sauce.png";
import ZzranImg from "./img/central/zzran-seasoning.png";
// 센트럴 물건
import Bat614 from "./img/central/bat614.png";
import Bat616 from "./img/central/bat616.png";
import Bat812 from "./img/central/bat812.png";
import SauceTong from "./img/central/sauce-container.png";
import Volcano from "./img/central/volcano-torch.png";
import CharcoalCover from "./img/central/charcoal-cover.png";
import MeatPaper from "./img/central/meat-paper.png";
import UpsizeBowl from "./img/central/upsize-bowl.png";
import NoodleBlade from "./img/central/noodle-blade.png";
import DisposableGrill from "./img/central/disposable-grill.png";
import Microwave from "./img/central/microwave.png";
import phMeter from "./img/central/phmeter.png";
import onjanggo from "./img/central/rice-warmer.png";
import chSideDish from "./img/central/side-dish-plate2.png";
import chJjajangBowl from "./img/central/jjajang-bowl-2.png";
import chTangsuyukBowl from "./img/central/tangsuyuk-plate-2.png";
import BMG from "./img/central/bmg.png";
import chDumplingPlaste from "./img/central/dumpling-plate.png";
import chRiceBowl from "./img/central/soup-bowl.png";

// 77물건
import SideDish from "./img/77/side-dish-plate.png";
import VegieProcessor from "./img/77/vege-processorr.png";
import Convertor from "./img/77/convertor.png";
import TangsuyukPlate from "./img/77/tangsuyuk-plate.png";
import StoneBowl from "./img/77/stone-plate.png";
import JjamppongBowl from "./img/77/jjamppong26.png";
import SpoonPaper from "./img/77/spoon-cover-long.png";
import SpoonPaper2 from "./img/77/spoon-cover-small.png";
import WoodenPlate from "./img/77/wooden-plate.png";
// 63 물건
import Charcoal from "./img/63/charcoal.png";
import ChoppingBoard from "./img/63/cutting-board.png";
import Cushion from "./img/63/cushion.png";
import NoodleScoop from "./img/63/noodle-scoop.png";
import SauceBowl from "./img/63/saucebowl.png";
import Roaster from "./img/63/roaster.png";
// 59 물건
import Hood from "./img/59/hood.png";
import RiceCooker from "./img/59/rice-cooker.png";

export const centralSauceData = [
  {
    title: "볶음소스",
    qty: 19,
    img: BokeumImg,
  },
  {
    title: "부추무침간장소스",
    qty: 27,
    img: BuchuImg,
  },
  {
    title: "부대찌개양념장",
    qty: 13,
    img: BudaeImg,
  },
  {
    title: "치킨염지제",
    qty: 2,
    img: ChickenPDImg,
  },
  // {
  //   title: "동치미농축육수",
  //   qty: 0,
  //   img: DongchimiImg,
  // },
  // {
  //   title: "맛내기육수",
  //   qty: 0,
  //   img: FlavouringImg,
  // },
  // {
  //   title: "진사골골드육수",
  //   qty: 2,
  //   img: GoldImg,
  // },
  {
    title: "짜장소스",
    qty: 11,
    img: JjajangImg,
  },
  {
    title: "얼큰짬뽕소스",
    qty: 8,
    img: JjamppongImg,
  },
  {
    title: "쫄면소스",
    qty: 11,
    img: JjolmyunImg,
  },
  {
    title: "족발모육수",
    qty: 16,
    img: JokbalImg,
  },
  {
    title: "칼국수소스",
    qty: 30,
    img: KalImg,
  },
  {
    title: "깐풍기소스",
    qty: 28,
    img: KkanpungImg,
  },
  {
    title: "콩가루",
    qty: 1,
    img: KongImg,
  },
  {
    title: "돼지갈비소스",
    qty: 12,
    img: MariPorkGalbiImg,
  },
  // {
  //   title: "정통멸치육수",
  //   qty: 8,
  //   img: MyulchiImg,
  // },
  {
    title: "온육수",
    qty: 30,
    img: OnyuksuImg,
  },
  {
    title: "한소반전믹스",
    qty: 18,
    img: PancakeImg,
  },
  {
    title: "일식돈가스소스",
    qty: 29,
    img: PorkCutletImg,
  },
  {
    title: "돈코츠라멘소스",
    qty: 25,
    img: RamenImg,
  },
  {
    title: "간편요리쌀파우더",
    qty: 1,
    img: RicePowderImg,
  },
  {
    title: "샤브쯔유",
    qty: 4,
    img: ShabuImg,
  },
  // {
  //   title: "간장게장소스",
  //   qty: 1,
  //   img: MariCrabImg,
  // },
  {
    title: "간장제육소스",
    qty: 10,
    img: MariJeyukImg,
  },
  {
    title: "제육양념소스",
    qty: 11,
    img: JeyukYangnyum,
  },
  {
    title: "양념게장소스",
    qty: 10,
    img: SpicyCrabImg,
  },
  {
    title: "매운족발양념소스",
    qty: 12,
    img: SpicyJokbalImg,
  },
  {
    title: "매운양식돈가스소스",
    qty: 29,
    img: SpicyPorkCutletImg,
  },
  {
    title: "탕농축소스",
    qty: 26,
    img: TangImg,
  },
  {
    title: "데리야끼소스",
    qty: 2,
    img: TeriImg,
  },
  // {
  //   title: "보람찬떡볶이소스",
  //   qty: 1,
  //   img: TteokbokiImg,
  // },
  {
    title: "정통가쓰오우동소스",
    qty: 10,
    img: UdonImg,
  },
  {
    title: "쯔란시즈닝",
    qty: 22,
    img: ZzranImg,
  },
];

export const centralItemData = [
  {
    title: "밧드 6 1/4",
    qty: 8,
    img: Bat614,
  },
  {
    title: "밧드 6 1/6",
    qty: 4,
    img: Bat616,
  },
  {
    title: "밧드 8 1/2",
    qty: 2,
    img: Bat812,
  },
  // {
  //   title: "석쇠(200x1Box)",
  //   qty: 1,
  //   img: DisposableGrill,
  // },
  {
    title: "소스통",
    qty: 5,
    img: SauceTong,
  },
  {
    title: "볼케이노토치",
    qty: 4,
    img: Volcano,
  },
  {
    title: "숯덮개",
    qty: 10,
    img: CharcoalCover,
  },
  {
    title: "미트페이퍼",
    qty: 10,
    img: MeatPaper,
  },
  {
    title: "짜장곱배기릇",
    qty: 18,
    img: UpsizeBowl,
  },
  {
    title: "면기 칼날",
    qty: 32,
    img: NoodleBlade,
  },
  {
    title: "전자레인지",
    qty: 1,
    img: Microwave,
  },
  // {
  //   title: "염도측정기",
  //   qty: 1,
  //   img: phMeter,
  // },
  {
    title: "온장고",
    qty: 2,
    img: onjanggo,
  },
  {
    title: "중홍반찬그릇",
    qty: 101,
    img: chSideDish,
  },
  {
    title: "중홍짜장기",
    qty: 60,
    img: chJjajangBowl,
  },
  {
    title: "중홍탕수육기",
    qty: 65,
    img: chTangsuyukBowl,
  },
  {
    title: "중홍군만두그릇",
    qty: 72,
    img: chDumplingPlaste,
  },
  {
    title: "중홍밥그릇",
    qty: 30,
    img: chRiceBowl,
  },
  {
    title: "BMG 도르래",
    qty: 20,
    img: BMG,
  },
];

export const bukittimahItemData = [];

export const jjajangItemData = [
  {
    title: "도자기 반찬그릇",
    qty: 100,
    img: SideDish,
  },
  {
    title: "야채절단기",
    qty: 1,
    img: VegieProcessor,
  },
  {
    title: "짜장곱배기그릇",
    qty: 32,
    img: UpsizeBowl,
  },
  {
    title: "변압기",
    qty: 2,
    img: Convertor,
  },
  {
    title: "탕수육도자기그릇",
    qty: 30,
    img: TangsuyukPlate,
  },
  // {
  //   title: "전골냄비",
  //   qty: 15,
  //   img: StoneBowl,
  // },
  {
    title: "26cm짬뽕기",
    qty: 20,
    img: JjamppongBowl,
  },
  {
    title: "수저집(Box)",
    qty: 8,
    img: SpoonPaper,
  },
  {
    title: "수저집(Box)",
    qty: 2,
    img: SpoonPaper2,
  },
  {
    title: "나무받침",
    qty: 20,
    img: WoodenPlate,
  },
];

export const bbqItemData = [
  {
    title: "참숯(Box)",
    qty: 40,
    img: Charcoal,
  },
  {
    title: "로스터",
    qty: 8,
    img: Roaster,
  },
  {
    title: "3색도마",
    qty: 1,
    img: ChoppingBoard,
  },
  // {
  //   title: "변압기",
  //   qty: 1,
  //   img: Convertor,
  // },
  {
    title: "방석",
    qty: 19,
    img: Cushion,
  },
  // {
  //   title: "면국자",
  //   qty: 5,
  //   img: NoodleScoop,
  // },
  {
    title: "소스볼",
    qty: 50,
    img: SauceBowl,
  },
];

export const bbqjjajangItemData = [
  {
    title: "자바라후드",
    qty: 15,
    img: Hood,
  },
  {
    title: "압력밥솥",
    qty: 2,
    img: RiceCooker,
  },
  {
    title: "로스터",
    qty: 33,
    img: Roaster,
  },
];
